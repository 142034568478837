.loading {
  border-radius: 0.45rem;
  font-weight: 500;
  color: #000;
  margin: 0;
  width: 60px;
  height: 60px;
  padding: 1rem 2rem;
  top: calc(50% - 2rem);
  left: calc(50% - 4rem);
  z-index: 100;
  position: absolute;
  text-align: center;

  img {
    height: 100%;
    animation: rotate 0.8s ease-in-out infinite;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
